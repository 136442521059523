<template>
  <div id="control-panel--color-switcher">
    <div class="label">Header theme selector</div>
    <p class="description">Click to change the header</p>
    <a href="javascript:;"
      v-for="(header, index) in availableHeaders"
      v-bind:key="index"
      v-bind:title="header"
      v-on:click="changeHeader(header)"
      class="header-option"
      v-bind:class="{ active: header == currentHeader, }">
      {{header}}
      <CheckCircleIcon color="yellowgreen" v-if="header == currentHeader"/>
    </a>
  </div>
</template>

<script>
  import { setHeader } from '@/utils/frontend';
  import { firestore, } from "@/services/firebase.service";
  import { CheckCircleIcon, } from "vue-feather-icons";
  export default {
    components: {
      CheckCircleIcon,
    },
    computed: {
      availableHeaders() {
        return this.$store.state.system.configs.THEME.availableHeaders;
      },
      currentHeader() {
        return this.$store.getters["system/header"];
      },
    },
    methods: {
      async changeHeader(header) {
        try {
          await firestore.doc("system/THEME").update({ header, });
          setHeader(header);
        } catch (e) {
          console.error(e);
          this.$swal(
            "Oops!",
            "An error has occurred",
            "error",
          );
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .header-option {
    border: solid 1px #dadada;
    border-radius: 4px;
    display: block;
    margin-bottom: 8px;
    padding: 4px 12px;
    &.active {
      background: #ebf2fd;
      display: flex;
      justify-content: space-between;
    }
  }
</style>